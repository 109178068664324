<template>
  <v-card class="bozo_quadro" variant="tonal">
    <template #title>
      <span @click="showEditPlayer = true">{{ player.name }}</span>
    </template>
    <template #append>
      <span> Pontuação: {{ player.total }}</span>
    </template>

    <v-row class="pa-4">
      <v-col cols="4" v-for="[k, v] in Object.entries(colunas)" :key="k">
        <v-row>
          <v-col cols="12" v-for="pedra in v" :key="pedra">
            <v-sheet
              elevation="1"
              :color="player.points[pedra] ? '#c7ecee' : 'grey-lighten-2'"
              class="text-center bozo_quadro-pedra"
              @click="bus('select', player, pedra)"
            >
              <span v-if="player.points[pedra] == -1" style="color: red">
                <b>x</b>
              </span>
              <span v-else-if="player.points[pedra]">
                {{ player.points[pedra] }}
              </span>
              <span v-else>{{ alias[pedra] }}</span>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>

  <v-dialog v-model="showEditPlayer">
    <form-player title="Editar Jogador" :player="player" @save="save" />
  </v-dialog>

  <v-sheet
    width="100%"
    class="justify-center align-center d-flex"
    color="transparent"
  >
    <v-btn
      color="error"
      fab
      variant="tonal"
      size="large"
      class="mt-2 mx-auto"
      icon="mdi-delete"
      v-if="canRemove"
      @click="bus('remove', player)"
    />
  </v-sheet>
</template>

<style scoped>
::v-deep.bozo_quadro-pedra {
  height: 64px;
  line-height: 64px;
  border-radius: 18px;

  &:hover {
    cursor: pointer;
  }
}
</style>

<script setup>
import { ref, defineEmits, defineProps } from "vue";
import FormPlayer from "./FormPlayer.vue";

const bus = defineEmits(["select", "remove", "edit"]);

defineProps({
  player: Object,
  canRemove: {
    type: Boolean,
    default: false,
  },
});

const colunas = ref({
  1: [1, 2, 3],
  2: ["full", "seguida", "quadrada", "general"],
  3: [4, 5, 6],
});

const alias = {
  1: "Ás",
  2: "Duque",
  3: "Terno",
  4: "Quadra",
  5: "Quina",
  6: "Sena",
  full: "Fu",
  seguida: "Seguida",
  quadrada: "Quadrada",
  general: "General",
};
const showEditPlayer = ref(false);

const save = (form) => {
  bus("edit", form);
  showEditPlayer.value = false;
};
</script>
