<template>
  <v-app class="bozo_app">
    <v-app-bar color="primary" app extended>
      <v-toolbar-title>Bozó</v-toolbar-title>
      <v-spacer />
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
        </template>

        <v-list>
          <v-list-item
            title="Adicionar Jogador"
            prepend-icon="mdi-plus"
            @click="showAddPlayer = true"
          />

          <v-list-item
            title="Reorganizar Jogadores"
            prepend-icon="mdi-sort"
            @click="showSortPlayer = true"
          />
        </v-list>
      </v-menu>

      <template #extension>
        <v-tabs
          :model-value="playerTab"
          @update:model-value="updateTab"
          align-tabs="title"
          show-arrows
        >
          <v-tab
            v-for="item in game.players.sort((a, b) => a.order - b.order)"
            :key="item"
            :value="item.id"
            :text="item.name + ' #' + item.id"
          />
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <v-container fluid style="height: 100%">
        <v-row style="height: 100%; align-items: center">
          <v-col cols="12">
            <v-window v-model="playerTab">
              <v-window-item
                :value="item.id"
                v-for="item of game.players"
                :key="item.id"
              >
                <QuadroJogador
                  :player="item"
                  :can-remove="game.players.length > 1"
                  @select="openPoints"
                  @remove="removePlayer"
                  @edit="updatePlayer"
                />
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <QuadroPonto
      :pontos="points"
      :selecionado="playerSelected"
      @close="showPoints = false"
      @update="updatePoints"
      v-model="showPoints"
    />

    <v-dialog v-model="showSortPlayer">
      <v-card title="Organizar Jogadores">
        <v-list>
          <draggable
            v-model="game.players"
            item-key="id"
            @change="updateOrderPlayers"
          >
            <template #item="{ element }">
              <v-list-item
                :title="element.name"
                ripple
                prepend-icon="mdi-drag"
              />
            </template>
          </draggable>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showWinner">
      <v-row>
        <v-col cols="12" v-if="game.players.length">
          <v-card>
            <v-sheet
              color="transparent"
              height="126"
              class="d-flex align-center flex-column justify-center"
            >
              <v-icon color="#f9ca24" size="86">mdi-trophy-outline</v-icon>
              <h3>Vencedor {{ rankPlayers.at(0).name }}</h3>
            </v-sheet>

            <v-card-text>
              <v-list density="compact">
                <v-list-item
                  v-for="(item, index) of rankPlayers"
                  :title="item.name"
                  :prepend-icon="`mdi-numeric-${index + 1}`"
                  :subtitle="`Pontuação Total: ${item.total}`"
                  :key="item.id"
                >
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>

    <v-dialog v-model="showAddPlayer">
      <form-player
        title="Adicionar Jogador"
        :player="playerStructure"
        @save="addPlayer"
      />
    </v-dialog>

    <v-footer app color="transparent">
      <v-btn
        variant="elevated"
        color="grey-darken-1"
        text="Reiniciar Partida"
        @click="endGame"
        block
        size="large"
      />
    </v-footer>
  </v-app>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, watch, onBeforeMount } from "vue";
import { playerStructure } from "@/store/game";
import QuadroPonto from "@/components/QuadroPonto.vue";
import QuadroJogador from "@/components/QuadroJogador.vue";
import FormPlayer from "./components/FormPlayer.vue";
import draggable from "vuedraggable";
import { uniqueId } from "lodash";

const showPoints = ref(false);
const showAddPlayer = ref(false);
const showWinner = ref(false);
const showSortPlayer = ref(false);
const formAddPlayer = ref({ name: null });
const playerTab = ref(null);
const playerSelected = ref({ player: {}, stone: null, points: [] });

const game = ref({
  id: null,
  status: "not_initialized",
  players: [
    {
      id: uniqueId(),
      name: "Jogador",
      group: false,
      total: 0,
      order: 0,
      points: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        full: null,
        seguida: null,
        quadrada: null,
        general: null,
      },
    },
  ],
});

const points = ref({
  1: [1, 2, 3, 4, 5],
  2: [2, 4, 6, 8, 10],
  3: [3, 6, 9, 12, 15],
  4: [4, 8, 12, 16, 20],
  5: [5, 10, 15, 20, 25],
  6: [6, 12, 18, 24, 30],
  especiais: {
    full: [10, 15],
    seguida: [20, 25],
    quadrada: [30, 35],
    general: [40, 100],
  },
});

const getPontosPossiveis = computed(
  () => (valor) =>
    typeof valor === "string"
      ? points.value.especiais[valor]
      : points.value[valor]
);

const rankPlayers = computed(() =>
  [...game.value.players].sort((a, b) => b.total - a.total)
);

const updateTab = (value) => {
  const player = game.value.players.find((h) => h.id == value);

  if (!player) {
    playerTab.value = 1;
    return;
  }

  playerTab.value = value;
};

const openPoints = (player, stone) => {
  showPoints.value = true;

  playerSelected.value = {
    player,
    stone,
    points: getPontosPossiveis.value(stone),
  };
};

const updatePoints = (stone, ponto) => {
  playerSelected.value.player.points[stone] = ponto;

  playerSelected.value.player.total = Object.entries(
    playerSelected.value.player.points
  )
    .filter((item) => item[1] && item[1] > -1)
    .map((item) => item[1])
    .reduce((a, b) => a + b, 0);

  const completed = [];

  for (const player of game.value.players) {
    // verificar se todos os pontos foram informados
    if (
      Object.entries(player.points).every(([, point]) => point && point >= -1)
    ) {
      completed.push(player.id);
    }

    const stoneAvailable = [1, 2, 3, 4, 5, 6].map(String);
    const generals = [];

    for (const [stone, point] of Object.entries(player.points)) {
      // verificar general de boca
      if (stone === "general" && point === 100) {
        endGame();
        break;
      }

      if (
        stoneAvailable.includes(stone) &&
        point === points.value[stone].at(points.value[stone].length - 1)
      ) {
        // verificar três generais
        generals.push(stone);
      }

      if (generals.length === 3) {
        endGame();
        break;
      }
    }
  }

  showPoints.value = false;

  if (completed.length === game.value.players.length) {
    endGame();
  }

  const currentIndex = game.value.players.findLastIndex(
    (v) => v.id === playerSelected.value.player.id
  );

  if (currentIndex === game.value.players.length - 1) {
    playerTab.value = game.value.players.at(0).id;
  } else {
    playerTab.value = game.value.players.at(currentIndex + 1).id;
  }
};

const addPlayer = (form) => {
  const totalPlayers = game.value.players.length;
  const lastUser =
    totalPlayers > 0 ? game.value.players.at(totalPlayers - 1) : 0;

  showAddPlayer.value = false;
  formAddPlayer.value = { name: null };

  game.value.players.push({
    id: uniqueId(),
    name: form.name,
    group: false,
    total: 0,
    order: lastUser.order + 1,
    points: {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      full: null,
      seguida: null,
      quadrada: null,
      general: null,
    },
  });
};

const removePlayer = (player) => {
  const { id } = player;
  const index = game.value.players.findLastIndex((h) => h.id === id);
  if (index > -1) game.value.players.splice(index, 1);
  playerTab.value = game.value.players.at(game.value.players.length - 1).id;
};

const updatePlayer = (updated) => {
  const player = game.value.players.find((h) => h.id === updated.id);
  player.name = updated.name;
};

const endGame = () => {
  showWinner.value = true;
};

const resetGame = () => {
  game.value.status = "new";
  game.value.hasWinner = false;
  let lastOrder = -1;

  for (const [, player] of game.value.players
    .sort((a, b) => b.total - a.total, { total: 0 })
    .entries()) {
    player.total = 0;

    for (const stone in player.points) {
      player.points[stone] = null;
    }

    if (lastOrder == -1) {
      player.order = 0;
      lastOrder = 0;
    } else {
      lastOrder++;
      player.order = lastOrder;
    }
  }
};

const updateOrderPlayers = (e) => {
  game.value.players.at(e.moved.newIndex).order = e.moved.oldIndex;
  e.moved.element.order = e.moved.newIndex;
};

watch(showWinner, (value) => {
  if (!value) {
    resetGame();
    return;
  }
});
</script>
