<template>
  <v-dialog v-model="model">
    <v-card title="Selecione a pontuação" style="overflow: hidden">
      <template #append>
        <v-btn icon small variant="flat" @click.stop="bus('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-divider></v-divider>
      <v-row class="pa-4">
        <v-col
          cols="4"
          class="px-1"
          v-for="ponto in selecionado.points"
          :key="ponto"
        >
          <v-sheet
            color="primary"
            elevation="1"
            class="text-center stone pontuar"
            @click="bus('update', selecionado.stone, ponto)"
          >
            {{ ponto }}
          </v-sheet>
        </v-col>

        <v-col cols="4">
          <v-sheet
            color="red"
            dark
            elevation="1"
            class="text-center stone"
            @click="bus('update', selecionado.stone, -1)"
          >
            Riscar
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script setup>
/* eslint-disable */
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  value: Boolean,
  pontos: Object,
  selecionado: Object,
});

const bus = defineEmits(["update", "close"]);

const model = computed(() => props.value);
</script>

<style scoped>
::v-deep .v-sheet.stone {
  height: 64px;
  line-height: 64px;
  border-radius: 18px;

  &:hover {
    cursor: pointer;
  }
}
</style>
