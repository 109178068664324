<template>
  <v-card :title="title">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="px-0">
          <v-text-field
            variant="outlined"
            label="Nome"
            placeholder="Fulano"
            hide-details="auto"
            dense
            v-model="form.name"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn variant="tonal" color="primary" block @click="bus('save', form)"
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";

const props = defineProps({ title: String, player: Object });
const bus = defineEmits(["save"]);

const form = ref(Object.assign({}, props.player));
</script>

<style lang="scss" scoped></style>
