import { defineStore } from "pinia";

export const playerStructure = {
  id: 1,
  total: 0,
  name: "Jogador",
  group: false,
  points: {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    full: null,
    seguida: null,
    quadrada: null,
    general: null,
  },
};

export const useGameStore = defineStore("game", {
  state: () => ({
    round: {
      id: null,
      room: null,
      winner: null,
      players: [{ ...playerStructure }],
    },
  }),

  getters: {
    hasWinner: (state) => state.round.winner,
  },

  actions: {
    finishGame() {
      console.log("end game", this.round);
    },
  },
});
