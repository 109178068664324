import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";

// Vuetify
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import './registerServiceWorker'

const vuetify = createVuetify({
  components,
  directives,
  ssr: true,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#00b894",
          secondary: "#00cec9",
          surface: "#f6f6f6",
          background: "#fff",
        },
      },
    },
  },
});

const pinia = createPinia();

createApp(App).use(vuetify).use(pinia).mount("#app");
